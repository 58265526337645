<template>
  <div class="container wapper">
    <div class="page-title">
      <h1 class="title">完善资料</h1>
    </div>
    <van-cell-group class="form" :border="false">
      <van-field class="form-item" placeholder="昵称" center v-model="nameValue" :border="false" :clearable="true"
        :maxlength="11" />

      <van-field class="form-item" placeholder="设置密码(6-20位英文或数字）" center v-model="passValue"
        :type="showPassword ? '' : 'password'" :border="false" :clearable="false" :maxlength="20">
        <template #right-icon>
          <div class="icon_eye" @click="switchPassShow">
            <img src="../../../assets/images/login/eye_close.png" class="icon" v-if="!showPassword" />
            <img src="../../../assets/images/login/eye_open.png" class="icon" v-if="showPassword" />
          </div>
        </template>
      </van-field>
    </van-cell-group>

    <van-button round type="primary" class="button" @click="submit">提交
    </van-button>
  </div>
</template>

<script>
import mixinLogin from '@/mixins/login'
import { accountRegister } from '@/api/user'
import commonMixins from "@/mixins/common";

export default {
  mixins: [mixinLogin, commonMixins],

  data () {
    return {
      showPassword: false,
      nameValue: '',
      isSubmitResponse: true,//提交防抖
    }
  },
  computed: {},
  mounted () {
    this.$util.setShare()
  },
  methods: {

    switchPassShow () {
      this.showPassword = !this.showPassword
    },
    submit () {
      if (!this.isSubmitResponse) {
        return false
      }
      this.isSubmitResponse = false

      let nameValue = this.nameValue,
        verifyPasswordResult = this.verifyPasswordValue()

      let param = {}

      if (!nameValue) {
        this.$toast('请输入昵称')
        return
      }

      if (!verifyPasswordResult.result) {
        this.$toast(verifyPasswordResult.msg)
        return
      }

      param.nickname = nameValue
      param.password = verifyPasswordResult.value
      param.phone = this.$route.query.phone
      param.regcode = this.$route.query.regcode

      // 用户注册
      accountRegister(param).then((res) => {
        this.isSubmitResponse = true
        let status = res.status
        this.$toast(res.message)
        if (status !== 1) {
          return
        }

        this.$store
          .dispatch('setUserToken', res?.data.token)
          .then(() => {
          })
          .catch(() => {
          })

        setTimeout(() => {
          this.$router.go(-2)
        }, 1000)
      })
    },
  },

}
</script>

<style lang="scss" scoped>
.wapper {
  background-color: #fff;
}

.page-title {
  @include flex_between_center;
  margin-bottom: 0.5rem;
  padding-top: 1rem;

  .title {
    font-size: 0.64rem;
    line-height: 0.88rem;
    font-weight: 600;

    &-button {
      font-size: 0.35rem;
      line-height: 0.88rem;

      color: var(--main-color);
    }
  }
}

.form {
  margin-bottom: 0.75rem;

  &-item {
    border-bottom: 0.01rem solid #ebecf0;
    height: 1.76rem;

    /deep/ .van-field__control {
      font-size: 0.43rem;
    }

    .text {
      font-size: 0.37rem;
      line-height: 0.37rem;
      color: #18191a;
      width: 2.4rem;
      border-left: 0.01rem solid #ebecf0;
      text-align: right;
      box-sizing: border-box;

      &-disable {
        color: #c2c5cc;
      }
    }
  }
}

.icon_eye {
  @include flex_center;
  padding: 0 0.2rem;

  .icon {
    width: 0.4rem;
  }
}
</style>
