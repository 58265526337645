const {getCodeText} = require('@/api/user')

module.exports = {
    data() {
        return {
            countTimeNumber: 60,
            countTimer: -1,

            phoneValue: '',
            codeValue: '',
            passValue: '',
        }
    },
    computed: {
        // 禁止验证码点击
        isDisableCode() {
            return !(this.phoneValue.length >= 11 && this.countTimer === -1)
        },
        // 验证码文本
        codeText() {
            let countTimer = this.countTimer
            return countTimer > 0 ? this.countTimeNumber + 's' : '发送验证码'
        },
    },
    methods: {
        // 获取验证码
        getCode(type) {
            if (this.isDisableCode) return

            let verifyPhoneResult = this.verifyPhoneValue()
            if (!verifyPhoneResult.result) {
                return
            }


            let data = {
                phone: verifyPhoneResult.value,
                type: type ? type : 'login',
            }

            getCodeText(data).then(() => {
                this.openCodeTimer()
            })
        },

        // 开启验证码倒计时
        openCodeTimer() {
            this.countTimer = setInterval(() => {
                if (this.countTimeNumber < 1) {
                    clearInterval(this.countTimer)
                    this.countTimeNumber = 60
                    this.countTimer = -1
                }
                this.countTimeNumber--
            }, 1000)
        },
        // 多参数验证
        multiValidation(arr) {
            let result = true
            arr.map((call) => {
                let o = call()
                if (!o.result) {
                    this.$toast(o.msg)
                    result = false
                }
            })
            return result
        },
        // 验证手机号
        verifyPhoneValue() {
            let value = this.phoneValue
            if (!(/^1[1234567890]\d{9}$/.test(value))) {
                return this.resultFactory(false, '请输入正确手机号', value)
            }
            return this.resultFactory(true, '', value)
        },

        // 验证密码
        verifyPasswordValue() {
            let value = this.passValue.toString()

            if (value.length <= 5) {
                return this.resultFactory(false, '密码至少6位', value)
            }
            return this.resultFactory(true, '', value)
        },

        // 验证验证码
        verifyCodeValue() {
            let value = this.codeValue.toString()
            if (value.length < 4) {
                return this.resultFactory(false, '密码至少6位', value)
            }
            return this.resultFactory(true, '', value)
        },

        resultFactory(result, msg = '', value = '') {
            return {result, msg, value}
        }
    },
}
